@import 'main.scss';

.paginated-gallery-container {
    .MuiPagination-root {
        padding-top: 20px;
        .MuiPaginationItem-root {
            color: $primary;
            font-family: $font_lato_bold;
            font-size: 14px;
            .MuiPaginationItem-icon {
                font-size: 25px;
            }
            &.Mui-selected {
                background-color: $yellow !important;
                height: 40px;
                width: 40px;
                border-radius: 100%;
            }
        }
    }
    .paginated-gallery-content {
        padding: 10px;
        .paginated-gallery-item-detail {
            padding: 5px;
        }
        .paginated-gallery-item {
            .paginated-gallery-item-file {
                position: relative;
                height: 225px;
                border-radius: 10px;
                box-shadow: 0px 3px 6px $transparentShadow;
                img {
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &.video-file {
                    .video-container {
                        height: 100%;
                        position: relative;
                        .video-pverlay {
                            border-radius: 10px;
                            position: absolute;
                            z-index: 1;
                            background: $primaryWithOpacity;
                            top: 0%;
                            left: 0%;
                            height: 100%;
                            width: 100%;
                            button {
                                padding: 5px;
                            }
                            svg {
                                font-size: 50px;
                                color: $primary;
                            }
                        }
                    }
                }
            }
            .paginated-gallery-item-title {
                color: $primary;
                font-size: 24px;
                font-family: $font_lato_bold;
                padding-top: 20px;
                a {
                    word-break: break-word;
                    color: $primary;
                    text-decoration: none;
                }
            }
            .paginated-gallery-item-description {
                -webkit-line-clamp: 3;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                text-align: left;
                color: $descriptionGrey;
                font-size: 16px;
                padding-top: 20px;
            }
        }
    }
    .second-gallery-slider-mobile {
        overflow: visible;
        .embla {
            width: 100%;
            overflow: visible;
            .embla__viewport {
                overflow: visible;
            }
            .gallery-item {
                padding: 10px;
            }
            .gallery-slider-item {
                box-shadow: 0px 3px 6px $transparentShadow;
                width: 100%;
                position: relative;
                height: 205px;
                border-radius: 10px;
                img {
                    border-radius: 10px;
                }
            }
        }
    }
}
