@import 'main.scss';

.MuiAutocomplete-noOptions {
    background: white;
    border: 1px solid $primary;
    border-radius: 5px;
}
.flight-manage-bloc-container {
    position: relative;
    justify-content: center;
    padding: 20px;
    .input-full {
        font-size: 20px !important;
        font-family: $font_lato_regular !important;
        color: $primary;
    }
    .input-empty {
        font-size: 20px !important;
        font-family: $font_lato_regular !important;
        color: $grey;
    }
    .MuiPickersDesktopDateRangeCalendar-calendar {
        min-height: 264px;
    }
    .MuiPickersDesktopDateRangeCalendar-arrowSwitcher {
        padding: 0px 16px 8px 16px;
    }
    .MuiInput-underline:after,
    .MuiInput-underline:before {
        content: none;
    }
    .search-input input {
        text-transform: capitalize;
    }

    .time-date-container {
        height: 100%;
    }
    label + .MuiInput-formControl {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 100%;
    }
    .form-content {
        padding: 10px 0;
    }
    .MuiInputBase-root input,
    .MuiInputBase-root input[disabled],
    .MuiInputBase-root input:disabled {
        font-size: 25px;
        opacity: 1;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        text-align: left;
        pointer-events: none;
        color: $primary;
    }
    .MuiInputBase-root input[disabled],
    .MuiInputBase-root input:disabled {
        color: $primary !important;
        -webkit-text-fill-color: $primary !important;
    }
    label.MuiInputLabel-formControl {
        transform: translate(0, 0) scale(1);
        color: $grey;
        font-size: 20px;
        font-family: $font_lato_regular;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: unset;
    }
    p.MuiFormHelperText-root {
        position: absolute;
        bottom: -5px;
        font-family: $font_lato_regular;
        text-transform: capitalize;
        color: $primary;
        font-size: 12px;
        margin-top: -10px;
        width: 100%;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        text-align: left;
        span {
            font-family: $font_lato_bold;
            text-transform: uppercase;
            margin-left: 5px;
        }
    }
    label.MuiInputLabel-formControl.MuiInputLabel-shrink {
        transform: translate(0, -15px) scale(0.6);
    }
    .icon-container {
        padding-left: 15px;
        align-items: center;
        display: flex;
        svg {
            .fill-blue {
                fill: $grey;
            }
            max-height: 20px;
        }
    }
    .MuiFormControl-root {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }
    .MuiTabPanel-root {
        padding: 0;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .MuiFormControl-root {
        width: 100%;
    }
    .reservation-control {
        padding-top: 20px;
        .MuiTabs-root {
            border-radius: 5px;
            background-color: $hoverColor;
            min-height: unset;
            padding: 5px;
            .MuiTabs-scroller {
                min-height: unset;
                height: fit-content;
            }
            button.MuiTab-root {
                opacity: 1;
                height: 40px;
                padding: 0;
                min-height: unset;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
            }
            button.MuiTab-root span.MuiTab-wrapper {
                z-index: 1;
                color: $primary;
                font-family: $font_lato_bold;
                font-size: 14px;
                flex-direction: row;
                svg {
                    margin-right: 5px;
                    .fill-grey {
                        fill: $primary;
                    }
                    .stroke-grey {
                        stroke: $primary;
                    }
                }
            }
            button.Mui-selected span.MuiTab-wrapper {
                color: $white;
                svg {
                    fill: $white;
                    .fill-blue,
                    .fill-grey {
                        fill: $white;
                    }
                    .stroke-grey {
                        stroke: $white;
                    }
                }
            }
            .MuiTabs-indicator {
                height: 100%;
                background-color: $primary;
                z-index: 0;
                border-radius: 5px;
            }
        }
    }
    .flight-reservation-container {
        max-width: 1240px;
        padding: 20px 15px;
        justify-content: center;
        display: flex;
        margin: auto;
        .flight-reservation-form-container {
            position: relative;
            @media only screen and (max-width: 1350px) {
                flex-wrap: wrap;
            }
        }
    }
    .flight-manage-bloc-content {
        max-width: 100%;
        width: 100%;
        background: $white;
        box-shadow: 0px 0px 15px $transparentShadow;
        border-radius: 5px;

        .airports-container {
            position: relative;
            width: fit-content;
            .departure-container,
            .destination-container {
                min-width: 200px;
                max-width: 200px;
            }
            .form-container-reservation-container {
                padding: 0 10px 10px !important;
            }
            .permute-btn {
                padding: 0;
                width: 40px;
                height: 40px;
                border: 1px solid $primary;
                position: absolute;
                top: calc(50% - 20px);
                background: $white;
                z-index: 3;
                &:hover svg {
                    transition: 0.7s;
                    transform: rotate(180deg);
                }
            }
        }
        .MuiInput-underline:after,
        .MuiInput-underline:before {
            content: none;
        }

        button.MuiTab-root {
            width: 33.3333%;
            max-width: 100%;
            opacity: 1;
            position: relative;
            font-family: $font_lato_bold;
            font-size: 14px;
            svg {
                margin-right: 9px;
                margin-left: 9px;
                .fill-blue {
                    fill: $white;
                }
                .fill-white {
                    fill: $primary;
                }
                &.Done-icon {
                    .fill-blue {
                        fill: $primary;
                    }
                    .fill-white {
                        fill: $white;
                    }
                }
            }
            &.Mui-selected {
                background: $white;
                color: $primary;
                font-family: $font_lato_regular;
                svg {
                    .fill-blue {
                        fill: $primary;
                    }
                    .fill-white {
                        fill: $white;
                    }
                    &.Done-icon {
                        .fill-blue {
                            fill: $white;
                        }
                        .fill-white {
                            fill: $primary;
                        }
                    }
                }
            }
            hr.left-divider {
                position: absolute;
                left: 0;
                height: 10px;
                top: 40%;
                background-color: $white;
            }
            hr.right-divider {
                position: absolute;
                right: 0;
                height: 10px;
                top: 40%;
                background-color: $white;
            }
        }
        header {
            height: fit-content;
            color: $white;
            background-color: $primary;
            box-shadow: none;
            z-index: 1;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            .MuiTabs-root {
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
            }
        }
        .MuiTabs-indicator {
            background: transparent;
            display: flex;
            justify-content: center;
            span {
                max-width: 20px;
                width: 100%;
                background-color: $primary;
            }
        }
        .form-container {
            &.flight-date-container {
                flex-grow: 1;
                max-width: 275px;
                min-width: 200px;
            }
        }
        .mini-form-container {
            flex-grow: 1;
            max-width: 250px;
        }
    }
    .departure-country-dropdown {
        background: $white;
        z-index: 2;
        .MuiAutocomplete-option[data-focus='true'],
        .MuiAutocomplete-option[aria-selected='true'] {
            background: white;
        }
        .MuiAutocomplete-option {
            padding: 0;
        }
        .country-collapse {
            color: $primary;
            font-size: 14px;
            padding: 10px 30px;
            .MuiAutocomplete-listbox {
                padding: 0;
            }
            &.collapse-open {
                background: $primary;
                color: $white;
            }
            .MuiListItemText-root span {
                font-family: $font_lato_bold;
            }
        }
        .MuiCollapse-entered {
            background-color: $hoverColor;
        }
        .MuiCollapse-wrapper {
            color: $primary;

            li {
                font-family: $font_lato_regular;
                font-size: 14px;
                text-transform: capitalize;
                .option-selected {
                    background-color: $white;
                    &.airport-option {
                        background-color: $white !important;
                    }
                }
                .airport-option {
                    padding: 10px 30px;
                    width: 100%;
                    background: $hoverColor;
                }
                &.MuiAutocomplete-option[aria-selected='true'] {
                    background: $hoverColor;
                }
                &:hover .airport-option {
                    background: $white;
                }
            }
            .bold-country-code {
                font-family: $font_lato_bold;
                margin-left: 5px;
            }
        }
    }
    @media only screen and (max-width: 1020px) {
        .flight-reservation-container {
            padding: 20px 0 !important;
        }
        .form-container-reservation-container {
            padding: 0 5px 10px !important;
        }
        .form-container-reservation-container
            .form-content.date-picker-container
            .date-dropdown {
            width: calc(100% - 15px) !important;
        }
    }
}
.mini-form-container,
.form-container {
    .form-container-reservation-container {
        padding: 0 10px 10px;
        height: 100%;

        .form-content {
            border: 1px solid $primary;
            border-radius: 5px;
            min-height: 100%;
            position: relative;
            min-height: 77px;

            &.passengers-form {
                .passengers-form-content {
                    height: 100%;
                    .icon-container {
                        svg {
                            max-height: none;
                            transform: scale(0.9);
                        }
                    }
                    .icon-container-open {
                        svg {
                            transform: scale(0.65);
                        }
                    }
                    .passenger-form-container {
                        align-items: center;
                    }
                    &.form-control-open {
                        label.MuiInputLabel-formControl {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .passenger-form-container {
                            align-items: unset;
                        }
                    }

                    .MuiInputBase-input {
                        font-family: $font_lato_bold;
                        font-size: 25px;
                        color: $primary !important;
                        height: 100%;
                    }
                }
                .passenger-form-container {
                    padding: 0 15px;

                    .MuiInputBase-adornedEnd input {
                        height: 100%;
                    }

                    .passenger-form-number {
                        font-size: 25px;
                        color: $primary;
                        font-family: $font_lato_bold;
                    }
                }

                button.passenger-btn {
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    &.MuiButton-root {
                        text-transform: none;
                    }
                    .MuiButton-label {
                        height: 100%;
                    }
                    &:hover {
                        background: unset;
                    }
                    .MuiInputAdornment-positionEnd svg {
                        fill: $primary;
                        font-size: 30px;
                    }
                }
                .passenger-container-popper {
                    top: calc(100% + 10px) !important;
                    left: unset !important;
                    right: 0 !important;
                    width: 280px !important;
                    border-radius: 5px;
                    transform: unset !important;
                    z-index: 2;
                    .passenger-content-popper {
                        border: 1px solid $primary;
                        box-shadow: 0px 0px 6px $greyShadowDrop;
                    }
                }
            }
            &.date-picker-container {
                position: unset;
                .date-dropdown {
                    width: calc(100% - 20px) !important;
                    z-index: 4 !important;
                    top: 100% !important;
                    left: 10px !important;
                    border-radius: 5px;
                    transform: unset !important;
                    .date-dropdown-paper.MuiPaper-root {
                        box-shadow: 0px 0px 6px $greyShadowDrop;
                        border: 1px solid $primary;
                        .MuiPickersDateRangePickerInput-root {
                            display: none;
                        }
                    }
                    .time-container {
                        padding: 0px 10px 10px 10px;
                    }
                }
                .date-range-delimiter {
                    margin: 0 !important;
                    line-height: normal;
                    height: 30px;
                    width: 30px;
                    svg {
                        fill: $grey;
                        font-size: 30px;
                    }
                }
                .MuiInputBase-input {
                    font-family: $font_lato_bold;
                    font-size: 25px;
                    color: $primary !important;
                    text-transform: uppercase;
                    height: 100%;
                }
                .date-selector-container-input {
                    padding: 0 15px;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    .MuiFormControl-left,
                    .MuiFormControl-right {
                        max-width: 100px;
                        flex-direction: column;
                    }
                    &.date-time-selector-container-input {
                        .MuiFormControl-left,
                        .MuiFormControl-right {
                            max-width: 135px;
                            min-width: 135px;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: flex-end;
                            p.MuiFormHelperText-root {
                                padding-left: 5px;
                                align-self: center;
                            }
                        }
                    }
                }
                .MuiPickersPopper-root {
                    position: relative !important;
                    width: 100% !important;
                    transform: unset !important;

                    .MuiPickersPopper-paper {
                        box-shadow: unset;
                        transition: none !important;
                    }
                    .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer {
                        width: 33.33%;
                        border-right: none;
                        .MuiPickersArrowSwitcher-iconButton {
                            &.MuiIconButton-root.Mui-disabled {
                                svg {
                                    opacity: 0.2;
                                }
                            }
                            svg {
                                fill: $primary;
                            }
                        }
                    }
                    .MuiPickersDay-today:not(.Mui-selected),
                    .MuiPickersDateRangeDay-dayInsideRangeInterval,
                    .MuiPickersDay-root,
                    .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
                        border-color: $primary;
                        background-color: unset;
                        font-size: 14px;
                        font-family: $font_lato_bold;
                        color: $primary;
                    }
                    .MuiPickersDesktopDateRangeCalendar-arrowSwitcher {
                        h6 {
                            color: $primary;
                            font-size: 25px;
                            font-family: $font_lato_bold;
                            text-transform: capitalize;
                        }
                    }
                    .MuiPickersCalendar-weekDayLabel {
                        color: $descriptionGrey;
                        font-size: 14px;
                        font-family: $font_lato_bold;
                        height: fit-content;
                    }
                    .MuiPickersDay-root.Mui-disabled {
                        opacity: 0.25;
                    }
                    .MuiPickersDay-root.Mui-selected {
                        background-color: $yellow;
                        color: $primary;
                    }
                    .MuiToolbar-root .MuiPickersToolbar-dateTitleContainer,
                    .MuiToolbar-root .MuiTypography-root {
                        display: none;
                    }
                    .MuiToolbar-regular {
                        height: 60px;
                        min-height: unset;
                        background: white;
                        padding: 0;
                        border-radius: 5px;
                    }
                }
                .ways-selector-container {
                    width: fit-content;
                    padding: 8px 20px;
                    label.MuiFormControlLabel-root {
                        padding-right: 20px;
                        span {
                            font-family: $font_lato_bold;
                            font-size: 14px;
                            color: $primary;
                            text-transform: capitalize;
                        }
                    }
                }
                @keyframes fadeIn {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
            }
            .combo-box-date-picker {
                padding: 0 15px;
                ul.MuiAutocomplete-listbox {
                    padding: 0;
                }
            }
            .MuiAutocomplete-root {
                height: 100%;
                z-index: 1;
                align-items: center;
                display: flex;
                .search-input {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
            .departure-country-dropdown {
                top: calc(100% + 7px) !important;
                right: 0 !important;
                width: 100% !important;
                transform: none !important;
                background: transparent !important;
                z-index: 4;
                .MuiAutocomplete-paper {
                    overflow: visible;
                    box-shadow: unset;
                    background: transparent;
                    .countries-history-bloc {
                        background: transparent;
                        width: 100%;
                        text-transform: capitalize;
                        &.countries-history-bloc-mobile {
                            .history-item-city-name {
                                font-size: 22px;
                            }
                            .history-item-detail {
                                font-size: 10px !important;
                                line-height: 13px;
                                margin-top: -5px !important;
                            }
                        }
                    }
                    .countries-bloc,
                    .history-bloc {
                        box-shadow: 0px 0px 6px $greyShadowDrop;
                        border-radius: 5px;
                        background: white;
                    }
                    .countries-bloc {
                        width: 100%;
                        border: 1px solid $lighterPrimary;
                    }
                    .history-bloc {
                        position: absolute;
                        left: calc(100% + 20px);
                        min-width: 130%;
                        max-width: calc(100vw - 90px);
                        padding: 10px;
                        .history-bloc-title {
                            color: $descriptionGrey;

                            svg {
                                height: 14px;
                                width: 17px;
                                margin-right: 5px;
                                .fill-blue {
                                    fill: $descriptionGrey;
                                }
                            }
                        }
                        .history-item-detail-container {
                            text-transform: capitalize;
                            .history-item-city-name {
                                -webkit-line-clamp: 1;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                word-wrap: break-word;
                                text-align: left;
                            }
                        }
                        .history-item {
                            cursor: pointer;
                            width: 100%;
                            box-shadow: 0px 0px 6px $greyShadowDrop;
                            border-radius: 5px;
                            margin: 10px 0;
                            color: $descriptionGrey;
                            font-size: 20px;
                            font-family: $font_lato_bold;
                            padding: 10px 20px;
                            .history-item-actions {
                                button.history-item-action {
                                    padding: 5px;
                                    svg {
                                        overflow: visible;
                                        &:hover {
                                            .led-pin {
                                                transform: translate(
                                                        -48px,
                                                        11px
                                                    )
                                                    rotate(-14deg);
                                                overflow: visible;
                                            }
                                        }
                                    }
                                }
                            }
                            .history-item-detail {
                                font-family: $font_lato_regular;
                                font-size: 10px;
                                margin-top: -5px;
                                text-align: left;
                                .history-item-detail-code {
                                    font-family: $font_lato_bold;
                                    text-transform: uppercase;
                                }
                            }
                            svg {
                                fill: $primary;
                                .fill-grey {
                                    fill: $primary;
                                }
                            }
                            &:hover {
                                background-color: $hoverColor;
                            }
                        }
                    }
                    .country-bloc-header {
                        font-size: 14px;
                        font-family: $font_lato_regular;
                        color: $descriptionGrey;
                        padding: 20px;
                        svg {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
.search-promo-content-info .city-info-container {
    font-size: 12px;
    color: $smallLink;
    padding-right: 10px;
    .city-info-txt {
        display: inline-block;
    }
    .city-name {
        text-transform: capitalize;
        font-family: $font_lato_bold;
    }
    a {
        text-decoration: none;
        color: $smallLink;
    }
}
button.flights-search-btn.MuiButton-root {
    color: $primary;
    background: $yellow;
    font-family: $font_lato_bold;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px 20px;
    &:hover {
        color: $white;
        background: $primary;
    }
    .loader-booking {
        width: 20px !important;
        height: 20px !important;
        margin-left: 5px;
        color: $primary;
    }
}
.search-promo-container {
    padding: 10px 10px;
    padding-bottom: 0;
    .flights-promo-content {
        width: fit-content;
        display: flex;
        padding: 10px 0;
        padding-bottom: 0;
    }
    .search-promo-content-info {
        padding: 10px 0;
        max-width: 50%;
    }
    .flights-promo-container {
        position: relative;
        margin-right: 20px;
        display: flex;
        align-items: center;
        min-height: 40px;
        min-width: 250px;
        justify-content: flex-end;
        .flights-promo {
            font-family: $font_lato_bold;
            font-size: 14px;
            color: $primary;
            padding: 0;
            transition: all 0.2s ease-in-out;
            position: relative;
            display: flex;
            align-items: center;
            .MuiButton-label {
                display: flex;
                align-items: baseline;
            }
            .add-icon {
                display: flex;
                align-items: baseline;
            }
            &:before,
            &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                width: 0px;
                height: 1px;
                transition: all 0.2s ease-in-out;
                transition-duration: 0.75s;
                opacity: 0;
                background-color: $primary;
            }

            &:before,
            &:after {
                left: 0;
            }
            svg {
                font-size: 15px;
                animation: reverseRotatePlus 1s;
            }

            &:hover {
                background: unset;
                svg {
                    animation: rotatePlus 1s forwards;
                }
                &:before,
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }

            @keyframes rotatePlus {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(90deg);
                }
            }

            @keyframes reverseRotatePlus {
                from {
                    transform: rotate(90deg);
                }
                to {
                    transform: rotate(0deg);
                }
            }
        }

        .promo-input-disabled {
            position: absolute;
            right: 0;
            top: 0;
            max-width: 100%;
            background: $hoverColor;
            border: 1px solid $primary;
            border-radius: 5px;
            height: 40px;
            width: 0;
            opacity: 0;
            ::placeholder {
                font-family: $font_lato_regular;
                text-transform: uppercase;
                font-size: 14px;
                color: $primary;
                opacity: 0.25;
            }
            &.promo-input {
                animation: showInput 0.5s forwards;
            }
            &.promo-animation-reverse {
                animation: showInputReverse 0.5s forwards;
            }
            .MuiInputBase-root {
                height: 100%;
                input {
                    padding-right: 20px;
                    padding-left: 20px;
                    font-size: 14px;
                }
            }

            @keyframes showInput {
                from {
                    width: 0;
                    opacity: 0;
                }
                to {
                    width: 100%;
                    opacity: 1;
                }
            }
            @keyframes showInputReverse {
                from {
                    width: 100%;
                    opacity: 1;
                }
                to {
                    width: 0;
                    opacity: 0;
                }
            }
        }
    }
}

.manage-flight-bloc {
    max-width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    margin: auto;

    .manage-flight-item {
        padding: 0 10px 10px;
    }

    .form-content {
        border: 1px solid $primary;
        border-radius: 5px;
        min-height: 100%;
        position: relative;
        height: 77px;
        min-height: 77px;
        .icon-container {
            padding-left: 15px;
            align-items: center;
            display: flex;
            svg {
                height: 17px;
                width: auto;
                .fill-blue {
                    fill: $grey;
                }
            }
        }
        .input-container {
            align-items: center;
            padding: 0 15px;
            .MuiTextField-root {
                width: 100%;
            }
        }
    }
}

.booking-number-input {
    p.MuiFormHelperText-root {
        font-size: 10px;
    }
}

.flights-info-container {
    padding: 20px 40px;
    max-width: 1240px;
    .MuiPagination-root {
        padding-top: 20px;
        .MuiPaginationItem-root {
            color: $primary;
            font-family: $font_lato_bold;
            font-size: 14px;
            cursor: default;
            &:hover {
                background-color: transparent;
            }
            .MuiPaginationItem-icon {
                font-size: 25px;
            }
            &.Mui-selected {
                background-color: $yellow !important;
                height: 40px;
                width: 40px;
                border-radius: 100%;
            }
        }
    }
    .flight-info-details-container {
        .MuiPagination-root {
            margin-top: auto;
        }
        .slick-next-arrow,
        .slick-prev-arrow {
            position: absolute;
            top: 0;
            z-index: 1;
            svg {
                color: $primary;
                font-size: 30px;
            }
        }
        .slick-prev-arrow {
            left: unset;
            right: 30px;
        }
        .slick-next-arrow {
            right: 0;
        }
        .slick-next-arrow.slick-disabled,
        .slick-prev-arrow.slick-disabled {
            opacity: 0.25;
        }
    }
    .slick-slider {
        width: 100% !important;
        .slider-flights-container {
            padding: 0px 10px;
        }
    }

    .gallery-slider {
        width: 800px !important;
    }
    &.flights-info-schedule {
        .gallery-slider {
            width: 90%;
        }
    }
    .no-flights-container {
        padding: 20px;
        color: $primary;
        font-size: 20px;
        font-family: $font_lato_bold;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .slider-pagination-dots {
        margin: -20px 0px 0px;
        button {
            cursor: default !important;
        }
    }
    .loader-flights-container {
        text-align: center;
        padding: 20px;
        margin: auto;
        color: $primary;
        font-size: 20px;
        font-family: $font_lato_bold;
    }
    .flight-detail-item {
        padding: 15px 20px;
        color: $primary;
        .flight-duration {
            font-family: $font_lato_bold;
            font-size: 17px;
            text-align: center;
        }
        &.status-PRG {
            .flight-to-icon {
                opacity: 0.25;
            }
        }
        &.status-VOL {
            .flight-status {
                color: $lightBlue;
            }
        }
        &.status-ARR {
            .flight-to-icon {
                .fill-grey {
                    fill: $green !important;
                }
                .stroke-grey {
                    stroke: $green !important;
                }
            }
            .flight-status {
                color: $green;
            }
        }
        &.status-RET {
            .flight-from-icon {
                .fill-grey {
                    fill: $orange !important;
                }
                .stroke-grey {
                    stroke: $orange !important;
                }
            }
            .flight-status {
                color: $orange;
            }
        }
        .flight-inscription {
            display: flex;
            justify-content: flex-end;
            .reserve-btn {
                background: $primary;
                color: $white;
                font-family: $font_lato_regular;
                padding: 6px 15px;
                &:hover {
                    background: $yellow;
                    color: $primary;
                }
            }
            .already_sub {
                .alert-badge {
                    fill: $yellow;
                }
            }
        }
        .flight-status {
            text-align: center;
            font-family: $font_lato_bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span {
                font-family: $font_lato_regular;
                font-size: 12px;
                color: $primary;
            }
        }
        .flight-number {
            display: flex;
            flex-direction: column;
            color: $primary;
            .flight-num-txt {
                font-size: 10px;
            }
            .flight-num {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 18px;
                font-family: $font_lato_bold;
                padding: 5px 5px 5px 0;
                .flight-num-content {
                    min-width: 60px;
                }
                .flight-radar-btn {
                    color: $primary;
                    padding: 0;
                    margin-left: 2px;
                }
            }
        }
        .city-time-name {
            color: $primary;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 5px;
            .city-name {
                text-transform: capitalize;
                text-align: center;
                width: fit-content;
                max-width: 100%;
                font-family: $font_lato_bold;
                font-size: 18px;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                padding: 4px;
            }
            .time {
                font-family: $font_lato_regular;
                font-size: 14px;
            }
        }
        &:nth-child(odd) {
            background: $hoverColor;
            .flight-route .flight-route-progress {
                background: $hoverColor;
            }
        }
        .flight-number {
            display: flex;
            flex-direction: column;
            color: $primary;
        }
        .flight-route {
            position: relative;
            display: flex;
            align-items: center;
            height: 30px;
            min-width: 115px;
            .flight-route-progress {
                position: absolute;
                z-index: 1;
                background: $white;
                margin: 5px;
                top: -100%;
                svg {
                    .fill-blue {
                        fill: $lightBlue;
                    }
                }
            }
            .flight-to-icon,
            .flight-from-icon {
                align-self: baseline;
                .fill-grey {
                    fill: $primary;
                }
                .stroke-grey {
                    stroke: $primary;
                }
            }
            .flight-from-icon {
                padding-right: 10px;
            }

            .flight-to-icon {
                padding-left: 10px;
            }
            .flight-route-border {
                position: relative;
                flex-grow: 1;
                hr {
                    border-top: 1px dashed $primary;
                    width: 100%;
                }
            }
        }
    }
    .flight-filter-bloc-container {
        padding-bottom: 20px;
        input,
        input:disabled,
        input[disabled] {
            font-size: 14px !important;
            font-family: $font_lato_regular !important;
            &::placeholder {
                text-transform: none !important;
            }
        }
        .flight-number-bloc-container {
            margin: 10px;
            border: 1px solid $primary;
            border-radius: 5px;
            width: 215px;
            max-width: 100%;

            .MuiInput-underline.Mui-disabled:before {
                border-bottom-style: hidden;
            }
            .MuiTextField-root {
                .MuiInputBase-root {
                    width: 100%;
                }
                input {
                    padding: 10px 15px;
                    &::placeholder {
                        color: $smallLink;
                        opacity: 1;
                    }
                }
            }
        }
        .flight-departure-bloc-container,
        .flight-destination-bloc-container {
            margin: 10px;
            background-color: $primary;
            color: $white;
            border-radius: 5px;
            padding: 10px 15px;
            position: relative;
            width: 215px;
            max-width: 100%;
            font-size: 14px;
            font-family: $font_lato_regular;
            svg {
                margin-right: 10px;
                .fill-grey {
                    fill: $white;
                }
            }
            p.MuiFormHelperText-root {
                display: none;
            }
            .departure-country-dropdown {
                transform: none !important;
                top: 100% !important;
                width: 100% !important;
                background: transparent;
                .country-bloc-header {
                    display: none;
                }
            }
            .combo-box-date-picker {
                height: 100%;
                input {
                    color: $white;
                    &::placeholder {
                        color: $white;
                        opacity: 1;
                    }
                }
                svg {
                    fill: $white;
                    .fill-grey {
                        fill: $white;
                    }
                    .stroke-grey {
                        stroke: $white;
                    }
                }
            }
        }
    }
    .previewText {
        font-size: 15px;
        color: $descriptionGrey;
        font-family: $font_lato_regular;
    }
    .flights-info-dates-header {
        max-width: 100%;
        height: 110px;
        margin: 0;

        .slick-next-arrow {
            right: -20px;
        }
        .slick-prev-arrow {
            left: -20px;
        }

        .slick-next-arrow,
        .slick-prev-arrow {
            top: 45%;
            z-index: 2;
            padding: 0;
            width: 35px;
            height: 35px;
        }
        .gallery-slider-item {
            height: 110px !important;
        }
        .slick-track {
            min-height: 110px !important;
        }
    }
    .date-selector-bloc {
        margin: 0 20px 20px;
        border: 1px solid $primary;
        border-radius: 5px;
        width: 155px;
        height: 101px;
        padding: 20px 10px;
        text-align: left;
        .MuiButton-label {
            display: flex;
            justify-content: center;
            height: 100%;
        }
        .date-icon {
            align-self: baseline;
            padding-right: 10px;
            svg {
                fill: $descriptionGrey;
                & * {
                    fill: $descriptionGrey;
                }
            }
        }
        .date-title {
            color: $primary;
            font-size: 28px;
            font-family: $font_lato_bold;
            line-height: 34px;
        }
        .date-label {
            color: $descriptionGrey;
            font-size: 14px;
            line-height: 18px;
            text-transform: capitalize;
        }
        .date-year {
            color: $primary;
            font-size: 10px;
            line-height: 13px;
        }
        &.selected {
            background-color: $primary !important;
            color: $white;
            .date-title,
            .date-label,
            .date-year {
                color: $white;
            }
            svg {
                fill: $white;
                & * {
                    fill: $white;
                }
            }
        }
    }
    &.flights-info-mobile {
        padding: 20px;

        .slider-flights-container {
            padding: 0;
            margin-top: 20px;
        }
        .MuiPagination-root {
            .MuiPagination-ul li {
                display: flex;
                align-items: center;
                height: 100%;
            }
            .MuiPaginationItem-root {
                background-color: $primary;
                overflow: hidden;
                min-width: unset;
                min-height: unset;
                color: transparent;
                height: 5px;
                width: 5px;
                margin: 5px;
                opacity: 0.5;
                border-radius: 100%;
                padding: 0;

                &.Mui-selected {
                    height: 10px;
                    width: 10px;
                    color: transparent;
                    opacity: 1;
                    background-color: $primary;
                }
            }
        }
        .flight-detail-item {
            position: relative;
            padding: 10px 5px;
            .flight-inscription {
                position: absolute;
                right: 0;
                top: 0;
            }
            .flight-number {
                align-items: center;
                .flight-num {
                    padding: 0 !important;
                }
            }
        }
        .flights-info-dates-header {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
        .flight-filter-bloc-container {
            .flight-number-bloc-container,
            .flight-departure-bloc-container,
            .flight-destination-bloc-container {
                width: 100%;
                margin: 5px 0;
            }
        }
        .combo-box-date-picker .MuiFormControl-root {
            .MuiInputBase-root {
                width: 100%;
            }
        }
        .date-selector-bloc {
            width: 120px;
            min-height: 50px;
            height: fit-content;
            padding: 5px;
            background-color: $hoverColor;
            border-color: $backgroundGrey;
            box-shadow: 0px 0px 6px #00000000;
            margin: 10px 0;
            .date-label {
                font-size: 10px;
                line-height: 10px;
            }
            .date-title-container {
                flex-direction: row;
                align-items: center;
            }
            .date-title {
                font-size: 22px;
                display: flex;
                align-items: center;
                padding-right: 2px;
                span {
                    font-family: $font_lato_regular;
                    font-size: 10px;
                    padding-left: 5px;
                }
            }
        }

        &.flights-info-schedule {
            padding-top: 20px;
            padding-bottom: 20px;
            .flight-detail-item {
                padding-bottom: 70px;
                justify-content: center;
                .flight-inscription {
                    bottom: 10px;
                    top: unset;
                    padding: 0 50px;
                    width: 100%;
                    .reserve-btn {
                        width: 100%;
                    }
                }
            }
        }
        .flights-info-dates-header {
            height: 75px;
            .gallery-slider-item {
                height: 75px !important;
            }
            .slick-track {
                min-height: 75px !important;
            }
        }
    }
}
