@import 'main.scss';

html,
body {
    font-family: $font_lato_regular !important;
    margin: 0;
    padding: 0;
}

.fill-white {
    fill: $white;
}
.fill-blue {
    fill: $primary;
}
.fill-yellow {
    fill: $yellow;
}
.global-container {
    max-width: -webkit-fill-available;
    max-width: -moz-available;
    width: 100%;
    padding-bottom: 20px;
}

.slick-next-arrow,
.slick-prev-arrow {
    position: absolute;
    bottom: -55px;
    height: 35px;
    width: 35px;
    top: unset;
    padding: 0;
    svg {
        font-size: 35px;
        color: $primary;
    }
    &.slick-disabled {
        opacity: 0.25;
    }
}
.gallery-slider {
    max-width: 900px;
    margin: auto;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;
    &.gallery-slider-mobile {
        height: unset;
        margin-bottom: 0px;
    }

    .mobile-slider {
        margin-left: 0 !important;
        padding-right: 15% !important;
        overflow: visible;
        &.gallery-slider-one {
            padding-right: 0 !important;
        }
        .embla {
            width: 100%;
            overflow: visible;
            .embla__viewport {
                overflow: visible;
            }
            .gallery-item {
                padding: 10px;
            }
            .gallery-slider-item {
                box-shadow: 0px 3px 6px $transparentShadow;
                width: 100%;
                position: relative;
                height: 230px;
                border-radius: 10px;
                img {
                    border-radius: 10px;
                }
            }
        }
    }
    .embla__dots {
        .dot-button {
            background: $primary;
            width: 5px;
            height: 5px;
            opacity: 0.5;
            padding: 0;
            margin: 2.5px;
            &.selected {
                width: 10px;
                height: 10px;
                opacity: 1;
            }
        }
    }
    .slick-next-arrow,
    .slick-prev-arrow {
        position: absolute;
        bottom: -55px;
        height: 35px;
        width: 35px;
        top: unset;
        padding: 0;
        svg {
            font-size: 35px;
            color: $primary;
        }
        &.slick-disabled {
            opacity: 0.25;
        }
    }
    .slick-next-arrow {
        right: calc(50% - 35px);
    }
    .slick-prev-arrow {
        left: calc(50% - 35px);
    }
    .slick-slider {
        min-width: 100%;
        text-align: center;
        padding: 20px;
        .slick-track {
            display: flex;
            align-items: center;
            min-height: 355px;
        }
        .slick-center {
            z-index: 1 !important;
            .gallery-slider-item {
                height: 350px;
                border-radius: 10px;
                width: 130% !important;
                margin-left: -15%;
                box-shadow: 0px 3px 6px $transparentShadow;
            }
        }
        .slick-slide {
            z-index: -1;
        }
        .gallery-slider-item {
            height: 250px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            transition: all 0.1s linear;
            position: relative;
            &:focus-visible {
                outline: none;
            }
        }
        img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
            &:focus-visible {
                outline: none;
            }
        }
        @media (max-width: 900px) {
            .slick-center {
                .gallery-slider-item {
                    width: 100% !important;
                    margin-left: 0 !important;
                }
            }
        }
    }
    &.one-slide-slider {
        max-width: 545px;
        .slick-slider {
            .slick-center {
                .gallery-slider-item {
                    width: 100% !important;
                    margin-left: 0% !important;
                }
            }
            .slick-slide {
                z-index: 1;
            }
        }
    }
}

.slick-prev:before,
.slick-next:before {
    content: none !important;
}

.fill-white {
    fill: $white;
}

.fill-service {
    fill: $serviceColor;
}

.fill-blue {
    fill: $primary;
}

.fill-grey {
    fill: $grey;
}

 .footer-powered-container {
      font-size: 10px;
      color: $primary;
      text-transform: none;
      a,
      button {
        font-size: 10px;
        color: $primary;
        text-decoration: none;
        text-transform: none;
      }
      .footer-powered {
        padding-right: 5px;
      }
    }