@import 'main.scss';

.collapse-bloc-item {
    margin: 10px;
    box-shadow: 0px 0px 15px $transparentShadow !important;
    width: 100%;
    &.Mui-disabled {
        background-color: $white !important;
    }
    .MuiAccordionSummary-root {
        padding: 0px 20px;
        min-height: 64px;
        color: $primary;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        &.Mui-disabled {
            opacity: 1;
        }
        .MuiTypography-body1 {
            font-size: 21px;
            font-family: $font_lato_bold;
        }
        .category-image {
            position: relative;
            height: 30px;
            width: 30px;
        }

        .add-icon {
            display: flex;
            align-items: baseline;
            color: $primary;
        }
    }

    .MuiAccordionDetails-root {
        display: block;
        padding: 0px 20px 0px 20px;

        .MuiAccordion-root:after {
            top: -1px;
            left: 0;
            right: 0;
            height: 1px;
            content: '';
            opacity: 1;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.12);
        }
        .collapse-bloc-content-sub-expand {
            margin: 0;
            flex-shrink: 0;
            box-shadow: unset;

            .MuiAccordionSummary-root {
                flex-direction: row-reverse;
                .MuiSvgIcon-root {
                    fill: $primary;
                }
            }
            .MuiAccordionSummary-root {
                .MuiTypography-body1 {
                    font-size: 14px;
                    font-family: $font_lato_regular;
                }
            }
            .MuiAccordionSummary-root.Mui-expanded {
                min-height: 64px;
                background-color: $white;
                color: $primary;
            }
            .MuiAccordionDetails-root {
                margin: 0px 0px 15px 15px;
                padding: 0px 0px 0px 16px;
                .MuiAccordion-root:after {
                    top: 0px;
                    left: 0;
                    right: 0;
                    height: 0;
                    content: '';
                    opacity: 1;
                    position: absolute;
                }
                .MuiAccordion-root:before {
                    top: 0px;
                    left: 0;
                    right: 0;
                    height: 0;
                    content: '';
                    opacity: 1;
                    position: absolute;
                }
                .MuiAccordion-root {
                    border-radius: 5px;
                    background-color: $backgroundWhiteLilac;
                }
                .collapse-bloc-content-sub-collapse-expand {
                    flex-shrink: 0;
                    margin: 0px 10px 20px 10px;
                    box-shadow: unset;
                    min-height: 64px;
                    .MuiAccordionSummary-root {
                        height: auto;
                        min-height: 64px;
                        border-radius: 5px;
                        flex-direction: row;
                        box-shadow: unset;
                        background-color: $backgroundWhiteLilac;
                        .MuiSvgIcon-root {
                            fill: $primary;
                        }
                    }
                    .MuiAccordionSummary-root.Mui-expanded {
                        position: relative;
                        z-index: 1;
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            height: 1px;
                            width: 60px;
                            margin-left: 20px;
                            border-bottom: 1px solid $primary;
                        }
                    }
                    .MuiAccordionDetails-root {
                        margin: 20px 10px 20px 5px;
                        .MuiTypography-body1 {
                            font-size: 14px;
                            font-family: $font_lato_regular;
                            .custom-page-html-div {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
        .direct-collapse {
            .MuiAccordionSummary-root {
                flex-direction: row-reverse;
                .MuiSvgIcon-root {
                    fill: $primary;
                }
            }
            .MuiAccordionSummary-root {
                .MuiTypography-body1 {
                    font-size: 14px;
                    font-family: $font_lato_regular;
                }
            }
            .MuiAccordionSummary-root.Mui-expanded {
                min-height: 64px;
                background-color: $white;
                color: $primary;
            }
            margin: 15px 0px 15px 0px;
            padding: 0px 0px 0px 16px;
            .MuiAccordion-root:after {
                top: 0px;
                left: 0;
                right: 0;
                height: 0;
                content: '';
                opacity: 1;
                position: absolute;
                background-color: rgba(0, 0, 0, 0.12);
            }
            .MuiAccordion-root:before {
                top: 0px;
                left: 0;
                right: 0;
                height: 0;
                content: '';
                opacity: 1;
                position: absolute;
                background-color: rgba(0, 0, 0, 0.12);
            }
            .MuiAccordion-root {
                border-radius: 5px;
                background-color: $backgroundWhiteLilac;
            }
            .collapse-bloc-content-collapse-expand {
                flex-shrink: 0;
                margin: 0px 10px 20px 10px;
                box-shadow: unset;
                min-height: 64px;
                .MuiAccordionSummary-root {
                    height: auto;
                    min-height: 64px;
                    border-radius: 5px;
                    flex-direction: row;
                    box-shadow: unset;
                    background-color: $backgroundWhiteLilac;
                    .MuiSvgIcon-root {
                        fill: $primary;
                    }
                }
                .MuiAccordionSummary-root.Mui-expanded {
                    position: relative;
                    z-index: 1;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 1px;
                        width: 60px;
                        margin-left: 20px;
                        border-bottom: 1px solid $primary;
                    }
                }
                .MuiAccordionDetails-root {
                    margin: 20px 10px 20px 5px;
                    .MuiTypography-body1 {
                        font-size: 14px;
                        font-family: $font_lato_regular;
                        .custom-page-html-div {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}
