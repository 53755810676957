$assets_root: '../';

$font_lato: 'Lato' !default;
$font_lato_path: 'fonts/Lato/Lato-Black.ttf' !default;

$font_lato_regular: 'Lato, Regular';
$font_lato_regular_path: 'fonts/Lato/Lato-Regular.ttf';

$font_lato_bold: 'Lato, Bold';
$font_lato_bold_path: 'fonts/Lato/Lato-Bold.ttf';

$font_lato_light: 'Lato, Light';
$font_lato_light_path: 'fonts/Lato/Lato-Light.ttf';

$font_lato_thin: 'Lato, Thin';
$font_lato_thin_path: 'fonts/Lato/Lato-Thin.ttf';
