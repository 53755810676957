@import 'colors.scss';
@import 'fonts.scss';

@font-face {
    font-family: $font_lato;
    src: url($assets_root + $font_lato_path) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font_lato_regular;
    src: url($assets_root + $font_lato_regular_path) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font_lato_bold;
    src: url($assets_root + $font_lato_bold_path) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font_lato_light;
    src: url($assets_root + $font_lato_light_path) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font_lato_thin;
    src: url($assets_root + $font_lato_thin_path) format('truetype');
    font-style: normal;
    font-display: swap;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-0.1em);
    }
    70% {
        transform: translateY(-0.3em);
    }
    100% {
        transform: translateY(0);
    }
}

.makeStyles-liIcon {
    fill: $backgroundGrey !important;
    color: $backgroundGrey;
    width: 32px !important;
    height: 23px !important;
    margin-left: 12px;
    margin-right: 12px;
}

.makeStyles-liIcon-RateTable {
    fill: #ed6c02 !important;
    color: $backgroundGrey;
    width: 32px !important;
    height: 23px !important;
    margin-left: 12px;
    margin-right: 12px;
    filter: invert(48%) sepia(47%) saturate(3747%) hue-rotate(4deg) brightness(97%) contrast(98%);
}