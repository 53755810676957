@import 'main.scss';

.paginated-list-container {
    .no-data-container {
        padding: 20px;
        color: $primary;
        font-family: $font_lato_bold;
    }
    .list-item-bloc {
        min-width: 350px;
        min-height: 300px;
        flex-grow: 1;
        padding: 20px;
        .button-container {
            margin-top: auto;
        }
        .list-item-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            box-shadow: none;
            @keyframes fadeOutOpacity {
                0% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
            @keyframes fadeInOpacity {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            .list-item-info-container {
                min-height: 250px;
                padding: 30px;
                box-shadow: 0px 3px 6px $transparentShadow;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                flex-grow: 1;
                .list-item-title-container {
                    font-size: 21px;
                    color: $primary;
                    font-family: $font_lato_bold;
                }
                .list-item-description-container {
                    color: $descriptionGrey;
                    font-size: 16px;
                    margin: 20px 0;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    word-wrap: break-word;
                    text-align: left;
                }
                .link-title {
                    background-color: $primary;
                    color: $white;
                    height: 40px;
                    padding: 0 20px;
                    font-family: $font_lato_bold;
                    &:hover {
                        background-color: $yellow;
                        color: $primary;
                    }
                }
            }
            .list-information-item-info-container {
                height: 300px;
                padding: 20px;
                box-shadow: 0px 0px 15px $transparentShadow;
                border-radius: 5px;
                flex-grow: 1;
                align-items: baseline;
                &.card-with-button {
                    box-shadow: unset;
                    box-sizing: content-box;
                    .jasmin-item-button {
                        padding-top: 25px;
                    }
                    &:hover {
                        box-shadow: 0px 0px 15px $transparentShadow;
                    }
                }
                .jasmin-item-button {
                    button {
                        background: $primary;
                        color: $white;
                        height: 40px;
                        padding: 10px 20px;
                        font-family: $font_lato_bold;
                        &:hover {
                            background: $yellow;
                            color: $primary;
                        }
                    }
                }
                .list-information-item-title-container {
                    font-size: 21px;
                    color: $primary;
                    font-family: $font_lato_bold;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    word-wrap: break-word;
                    text-align: left;
                }
                .list-information-item-icon-container {
                    height: 85px;
                    align-items: center;
                    padding-bottom: 20px;
                    overflow: hidden;
                    svg.svg-service-plus {
                        width: 150px;
                        max-width: 100%;
                    }
                    img {
                        object-fit: contain;
                        height: 100%;
                        max-width: 100%;
                    }
                }
                .list-information-item-divide {
                    border-top: 2px solid $primary;
                    position: relative;
                    width: 60px;
                    padding-bottom: 30px;
                }
                .list-information-item-description-container {
                    color: $descriptionGrey;
                    font-size: 16px;
                    padding: 10px 0 0;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    word-wrap: break-word;
                    text-align: left;
                }
            }
            .list-information-item-info-container-hover {
                background-color: $primary;
                height: 300px;
                padding: 20px;
                box-shadow: 0px 0px 15px $transparentShadow;
                border-radius: 5px;
                flex-grow: 1;
                .list-information-item-category-container {
                    color: $white;
                    margin: 4px 0px;
                    cursor: pointer;
                    &:hover {
                        .item-category-title {
                            padding-left: 30px;
                            font-family: $font_lato_bold;
                        }
                        .item-category-all {
                            padding-left: 30px;
                        }
                    }
                    .item-category-title {
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        text-align: left;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                        word-break: break-word;
                        text-transform: uppercase;
                        padding-left: 0px;
                        font-size: 16px;
                        height: 20px;
                        font-family: $font_lato_regular;
                        transition: all 250ms ease-in-out 0ms;
                    }
                    .item-category-all {
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        text-align: left;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                        word-break: break-word;
                        text-transform: uppercase;
                        padding-left: 0px;
                        font-size: 16px;
                        height: 20px;
                        font-family: $font_lato_bold;
                        transition: all 250ms ease-in-out 0ms;
                        &:hover {
                            padding-left: 30px;
                        }
                    }
                    .item-category-icon {
                        margin-right: auto;
                        margin-bottom: auto;
                        margin-top: -2px;
                    }
                }
                &:hover {
                    animation-name: fadeInOpacity;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in-out;
                    animation-duration: 0.25s;
                    &::after {
                        animation-name: fadeOutOpacity;
                        animation-iteration-count: 1;
                        animation-timing-function: ease-in-out;
                        animation-duration: 0.25s;
                    }
                }
            }
            .list-item-img-container {
                position: relative;
                height: 175px;
                div:first-of-type {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                }
                img {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    transition-duration: 0.2s;
                }
            }
            &:hover {
                .list-item-img-container img {
                    transform: scale(1.05);
                }
            }
            .price-info {
                position: relative;
                align-items: end;
                .price-container {
                    height: fit-content;
                    background: $yellow;
                    color: $primary;
                    padding: 7px 4px 7px 9px;
                    width: fit-content;
                    max-width: 100%;
                    border-radius: 2px 0px 0px 2px !important;
                    margin-top: 15px;
                    margin-right: -7.5px;
                    .price-content {
                        font-family: $font_lato_bold;
                        display: flex;
                        font-size: 28px;
                        .price-currency {
                            font-size: 14px;
                        }
                    }
                    .date-content {
                        padding-left: 8px;
                        font-family: $font_lato_regular;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .list-promotion-item-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            box-shadow: none;
            padding: 20px 0px 0px 0px;

            .list-promotion-item-info-container {
                padding: 30px 30px 30px 0px;
                flex-grow: 1;
                width: 35%;
                .list-item-title-container {
                    font-size: 28px;
                    color: $primary;
                    font-family: $font_lato_bold;
                }
                .list-item-description-container {
                    font-size: 16px;
                    text-align: left;
                    margin: 20px 0;
                    color: $descriptionGrey;

                    .list-item-description {
                        -webkit-line-clamp: 7;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                        word-break: break-word;
                    }
                }
                .link-title {
                    background-color: $primary;
                    color: $white;
                    height: 40px;
                    padding: 0 20px;
                    font-family: $font_lato_bold;
                    &:hover {
                        background-color: $yellow;
                        color: $primary;
                    }
                }
                .list-item-see-more-btn {
                    font-size: 16px;
                    color: $primary;
                }
            }
            .list-promotion-item-img-container {
                box-shadow: 0px 3px 6px #00000040;
                border-radius: 10px;
                margin: 0px 30px 0px 0px;
                position: relative;
                height: 350px;
                width: 55%;
                img {
                    border-radius: 10px;
                }
            }
            .price-info {
                position: relative;
                align-items: end;
                .triangle {
                    border-color: $secondYellow transparent transparent
                        $secondYellow;
                    border-style: solid;
                    border-width: 5px 5px 5px 5px;
                    height: 0px;
                    width: 0px;
                    margin-right: -10px;
                    margin-left: auto;
                }
                .price-container {
                    height: fit-content;
                    background: $yellow;
                    color: $primary;
                    padding: 7px 4px 7px 9px;
                    width: fit-content;
                    max-width: 100%;
                    border-radius: 2px 0px 0px 2px !important;
                    margin-top: 15px;
                    margin-right: -10px;
                    margin-left: auto;
                    .price-content {
                        font-family: $font_lato_bold;
                        display: flex;
                        font-size: 28px;
                        .price-currency {
                            font-size: 14px;
                        }
                    }
                    .date-content {
                        padding-left: 8px;
                        font-family: $font_lato_regular;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .list-promotion-item-container-mobile {
            height: 100%;
            display: flex;
            flex-direction: column;
            box-shadow: none;

            .list-promotion-item-info-container {
                padding: 20px 0px 0px 0px;
                flex-grow: 1;
                .list-item-title-container {
                    font-size: 28px;
                    color: $primary;
                    font-family: $font_lato_bold;
                }
                .list-item-description-container {
                    font-size: 16px;
                    text-align: left;
                    margin: 20px 0;
                    color: $descriptionGrey;

                    .list-item-description {
                        -webkit-line-clamp: 7;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                        word-break: break-word;
                    }
                }
                .link-title {
                    background-color: $primary;
                    color: $white;
                    height: 40px;
                    padding: 0 20px;
                    font-family: $font_lato_bold;
                    &:hover {
                        background-color: $yellow;
                        color: $primary;
                    }
                }
                .list-item-see-more-btn {
                    font-size: 16px;
                    color: $primary;
                }
            }
            .list-promotion-item-img-container {
                box-shadow: 0px 3px 6px #00000040;
                border-radius: 10px;
                margin: 0px;
                position: relative;
                height: 250px;
                width: 100%;
                img {
                    border-radius: 10px;
                }
            }
            .price-info {
                position: relative;
                align-items: end;
                .triangle {
                    border-color: $secondYellow transparent transparent
                        $secondYellow;
                    border-style: solid;
                    border-width: 5px 5px 5px 5px;
                    height: 0px;
                    width: 0px;
                    margin-right: -10px;
                    margin-left: auto;
                }
                .price-container {
                    height: fit-content;
                    background: $yellow;
                    color: $primary;
                    padding: 7px 4px 7px 9px;
                    width: fit-content;
                    max-width: 100%;
                    border-radius: 2px 0px 0px 2px !important;
                    margin-top: 15px;
                    margin-right: -10px;
                    margin-left: auto;
                    .price-content {
                        font-family: $font_lato_bold;
                        display: flex;
                        font-size: 28px;
                        .price-currency {
                            font-size: 14px;
                        }
                    }
                    .date-content {
                        padding-left: 8px;
                        font-family: $font_lato_regular;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
